@charset "utf-8";
@import "../_partial/commonFunctions";
@import "../_partial/globalVariables";

.Login {
	background: url(../image/global/background.jpg) center center no-repeat;
	background-size: cover;
	position: relative;

	&:before {
		background: rgba(0,0,0,0.6);
		content: '';
		left: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.Login-container{
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100vh;
	position: relative;
	z-index: 5;
}

.Login-formHeader {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
}

.Login-formItem--field {
	display: flex;
	flex-flow: column;
	margin-bottom: 25px;
	width: 350px;

	label {
		color: $lynch;
		font-family: opensans-regular, $fontOptions;
		font-size: 11px;
		margin-bottom: 5px;
	}

	input {
		background: transparent;
		border: none;
		border-bottom: 1px solid $lynch;
		color: $lynch;
		font-family: opensans-light, $fontOptions;
		font-size: 22px;

		&:focus {
			outline: none;
		}
	}
}

.Login-formItem--fieldButton {
	display: flex;
	justify-content: flex-end;
	margin-top: 50px;
}